import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { Row, Container } from "react-bootstrap"

import profile from "../images/profile.jpg"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Contact = () => (<>
  <Layout>
    <Seo title="Home" />
    <Container >
    <img className="corner-img mx-auto" src={'/'} />

       <Link className="black-href" to="/">←</Link>

    </Container>


  </Layout>
</>
)

export default Contact
